@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root, #app {
    width: 100%;
    min-height: 100vh;
    max-height: max-content;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}